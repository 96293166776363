import { useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget'
import { navigate } from 'gatsby';


const useNetlifyIdentity = () => {


    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [authReady, setAuthReady] = useState(false)

    useEffect(() => {


        netlifyIdentity.on('login', (user) => {


            setUser(user)

            netlifyIdentity.close()


            navigate('/main')

        })

        netlifyIdentity.on('logout', () => {

            setUser(null)
            navigate('/')


        })

        netlifyIdentity.on('init', (user) => {


            setUser(user)
            setAuthReady(true)

        })


        netlifyIdentity.init()


    }, [])

    const login = () => {
        netlifyIdentity.open()
    }


    const logout = () => {
        netlifyIdentity.logout()

    }


    const [cloudRunToken, setCloudrunToken] = useState(null)

    function generateCloudRunToken() {

        netlifyIdentity.refresh().then(res => {

            const urlString = '/.netlify/functions/get-token'

            fetch(urlString,
                {
                    headers: {
                        'Authorization': `Bearer ${res}`
                    }
                }
            )
                .then(res => {

                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    return res.json()
                })
                .then(data => {

                    setCloudrunToken(data.token)
                })



        })




    }


    return { authReady, user, error, login, logout, cloudRunToken, generateCloudRunToken }
}

export default useNetlifyIdentity;